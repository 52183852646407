import React from "react";
// import { useIntl } from "gatsby-plugin-intl";
// import Layout from "../components/Layout";
// import SEO from "../components/SEO";
// import Container from "../components/Container";
// import { useMount } from "react-use";

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    window.location = "/";
  }

  return null;
};

export default NotFoundPage;
